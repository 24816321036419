.app {
  width: min(300px, 90vw);

  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 16px;
    color: var(--token-light-100);
    margin-bottom: 32px;
  }

  .button {
    display: block;
    width: 100%;
  }
}
